section.module-advantages{
	$moduleVerticalSpace: $spaceBig;

	background-color:$colorGrayLight;
	padding:$moduleVerticalSpace 0;
	text-align:center;

	.advantages-title{
		display:flex;
		align-items:center;
		margin-bottom:$spaceMedium;

		h2{
			flex-grow:1;
		}

		.advantages-badge{
			min-width:100px;
			max-width:200px;
			width:10vw;
			height:auto;
			margin-top:-70px;
			margin-left:$spaceMedium;

			@include screen-max(md){
				margin-top:0;
			}
		}
	}

	.advantages-intro{
		margin-bottom:$spaceBig;

		h2{
			@include fontH2Home;
		}
	}

	.col{
		margin-bottom:$spaceMedium;
		min-width:200px;
	}

	.advantages-icon{
		display:inline-block;
		border-radius:50%;
		text-align:center;
		line-height:1;

		img{
			height:auto;
			width:10vw;
			min-width:60px;
			max-width:120px;
		}
	}

	.advantages-text{
		display:block;
		margin-top:$spaceSmall;
		font-size:rem(24);
		font-weight:$fontWeightBold;
	}

	.s-btn{
		margin-top:$spaceMedium;
	}
}
