header.page-header{
	height:$headerHeight;

	@include screen-max($mobileBreakpoint){
		height:$headerHeight / 100 * 60;
	}

	.container,
	.row,
	.col{
		height:100%;
	}

	.logo-col{
		white-space: nowrap;

		.img-aligner,
		img{
			display:inline-block;
			vertical-align:middle;
		}

		.img-aligner{
			height:100%;
		}

		img{
			max-width:100%;
			max-height:80%;
		}
	}

	@import 'header/nav';
	@import 'header/nav-content-basics';
	@import 'header/nav-content-mobile';
}
