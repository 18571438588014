.s-btn{
	display:inline-block;
	color:$colorWhite;
	background-color:$colorGreen;
	padding:$spaceSmall $spaceMedium;
	border-radius:5px;
	border:0;
	cursor:pointer;
	text-transform:uppercase;

	&:hover{
		text-decoration:none;
		color:$colorWhite;
		background-color:lighten($colorGreen, 5%);
	}
}
