nav{
	height:100%;

	.nav-inner-wrapper,
	.mobile-container{
		height:100%;
	}

	ul{
		@include resetUl;

		@include screen-between(md, lg){
			font-size:0.9em;
		}

		//only flex and margin it on desktop
		@include screen-min(md){
			height:50%;
			text-align: right;
			display:flex;
			align-items:center;
			justify-content:flex-end;

			li + li{
				margin-left:$spaceMedium;
			}
		}

		li{
			display:inline-block;

			&[data-active="true"] a{
				color:$colorGreen;
			}
		}

		a{
			color:$colorBlack;

			&:hover{
				text-decoration:none;
				color:$colorGreen;
			}
		}
	}

	ul.nav-main{
		font-weight:bold;
	}

}
