body.layout-home{
	main section + section{
		margin-top:$spaceBig;
	}

	.module-offerte{
		background-color:$colorGrayLight;
		padding:$spaceBig 0;

		h2, .offerte-lead{
			text-align:center;
		}

		h2{
			color:$colorGrayDark;
			font-size:3rem;
		}

		.offerte-lead{
			margin-bottom:$spaceBig;
		}

		form.userform{
			input,
			textarea{
				border:0;
				background-color:$colorWhite;
	      padding:5px 8px;
			}

			.form-group-legend{
				text-align:center;
				color:$colorGrayDark;
				margin:$spaceBig 0;
			}
		}
	}

	footer.page-footer{
		margin-top:0;
	}
}
