.offerte-button{
	z-index:zIndex(offerteButton);
	display:block;
	position:fixed;
	top:50%;
	right:0;
	transform-origin:right bottom;
	transform:rotate(-90deg) translateX(100%);
	border-radius:0;
	font-size:1.8rem;

	//mobile
	$mobile: map-get($grid-breakpoints, sm);
	@media (max-width:$mobile){
		top:auto;
		left:0;
		right:0;
		bottom:0;
		transform:none;
		text-align: center;
	}
}
