article.bootcamp-place{
	.hotels{
		border-top:1px solid $colorGrayDark;
		border-bottom:1px solid $colorGrayDark;

		.hotel{
			padding-top:$spaceGrid / 2;
			padding-bottom:$spaceGrid / 2;
		}

		.hotel-stars{
			white-space:nowrap;
		}

		.hotel-location{
			display:block;

			*{
				vertical-align:middle;
			}

			.hotel-location-icon{
				color:$colorGrayDark;
				margin-right:5px;
			}

			span{
				font-size:0.9em;
			}
		}

		.divider{
			width:100%;
			height:1px;
			border-bottom:1px solid $colorGrayDark;
		}

		@mixin bootcampPlaceMobile{
			border-bottom:0;

			.divider{
				display:none;
			}

			.hotel{
				padding-bottom:0;

				&:after{
					content:'';
					display:block;
					border-bottom:1px solid $colorGrayDark;
					padding-top:$spaceGrid / 2;
				}
			}
		}

		@include screen-max(sm){ @include bootcampPlaceMobile }
		@include screen-between(md, lg){ @include bootcampPlaceMobile }
	}
}
