.tooltip{
	color:$colorGreen;
	font-size:1.1em;

	//correct vertical alignment
	position:relative;
  top:-1px;

	//give it more space from text
	left:3px;
}

.tippy-popper .tippy-tooltip{
	background-color:$colorGreen;
	border-radius:0;

	.tippy-arrow{
		border-top-color:$colorGreen;
	}
}
