@include screen-max($mobileBreakpoint){
	nav .nav-inner-wrapper{
		background-color:$colorWhite;

		.mobile-container{
			display:flex;
			flex-direction:column;
		}

		label[for="nav-trigger"]{
			text-align:center;
			margin:$spaceMedium 0;
		}

		ul{
			li{
				display:block;
				text-align:center;

				&[data-active="true"],
				&:hover{
					a{
						background-color:$colorGreen;
						color:$colorWhite;
					}
				}
			}

			a{
				display:block;
				padding:$spaceSmall;
				border:2px solid $colorGreen;
				color:$colorGreen;
			}
		}

		ul.nav-main{
			li + li{
				margin-top:$spaceSmall;
			}
		}

		ul.nav-metanav{
			order:99;
			display:flex;
			flex-direction:row;
			justify-content:space-between;
			flex-wrap:wrap;
			margin-top:$spaceSmall;

			li + li{
				margin-left:$spaceSmall;
			}

			li{
				flex-grow:1;
				margin-bottom:$spaceSmall;

				&[data-active="true"],
				&:hover{
					a{
						background-color:$colorGrayDark;
						color:$colorWhite;
					}
				}
			}

			a{
				border-color:$colorGrayDark;
				color:$colorGrayDark;
			}
		}
	}
}
