section.module-hero{
	$overlayColor: rgba($colorGreen, 0.5);

	.hero-background{
		width:100%;
		background-position:center;
        background-size: cover;
        background-repeat: no-repeat;

		.container{
			padding-top:$spaceSmall;
			padding-bottom:$spaceSmall;
		}

		.container,
		.row,
		.col,
		.hero-content{
			height:100%;
		}

		.hero-content{
			padding:0 $spaceMedium;
		}

		.hero-content h1{
			font-size:rem(42);
			font-weight:$fontWeightBold;
			color:$colorWhite;

			//keep font in bounds on desktop
			//(needs more than basic typo regulation because of its massive size)
			@include screen-max(xl){
				font-size:rem(36);
			}

			@include screen-max(lg){
				font-size:rem(28);
			}

			//reset font for mobile since there are no bounds
			@include screen-max($mobileBreakpoint){
				font-size:rem(42);
			}
		}

		//desktop
		@include screen-min($mobileBreakpoint){
			@include ratio(20, 8);

			& > div{
				$t: transparent;
				$g: $overlayColor;
				$rightHalfOverlay: to right, $t 0%, $t 50%, $g 50%, $g 100%;

				background:linear-gradient($rightHalfOverlay);
			}

			.hero-content{
				display:flex;
				flex-direction:column;
				justify-content:center;
			}
		}

		//mobile
		@include screen-max($mobileBreakpoint){
			& > div{
				background:$overlayColor;
			}

			.container{
				padding-top:$spaceBig;
				padding-bottom:$spaceBig;
			}

			.hero-content{
				padding:0;
			}
		}
	}

}
