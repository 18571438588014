.cookie-notice{
	z-index:zIndex(cookieNotice);
	position:fixed;
	left:0;
	right:0;
	bottom:0;
	background-color:$colorWhite;
	border-top:10px solid $colorGreen;

	.cookie-notice-inner{
		display:flex;
		justify-content:space-between;
		align-items:center;
		margin-top:$spaceMedium;
		margin-bottom:$spaceMedium;

		button{
			margin-left:$spaceBig;
		}
	}
}
