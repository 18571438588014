body.layout-bootcamp{
	.page-head{
		background-color:$colorGrayLight;
		text-align:center;

		img{
			max-width:100%;
		}
	}

	.destination-intro{
		margin:$spaceBig 0;
		font-size:em(18);
	}

	.destination-nav{
		margin-bottom:$spaceBig;

		ul{
			@include resetUl;

			li{
				display:block;
			}

			li + li{
				margin-top:1px;
			}

			a{
				display:block;
				padding:$spaceSmall;
				background-color:$colorGrayLight;
				color:$colorGrayDark;
			}

			a:hover,
			li[data-active="true"] a{
				text-decoration:none;
				background-color:$colorGreen;
				color:$colorWhite;
			}
		}
	}

	.destination-content{
		margin-bottom:$spaceBig;
	}

	article + article{
		margin-top:$spaceBig;
	}
}
