form.userform{
    &, .form-group-inner{
      @extend .row;
    }

    & > *,
    .form-group-inner > *{
      @extend .col-12;
      @extend .col-md-6;
    }

    .form-field.form-field-wide,
    .form-group,
    .form-group-legend,
    .buttons{
      flex:0 0 100%;
      max-width:100%;
    }
}
