@import 'form/grid';

form.userform{
  .form-field{
    margin-bottom:$spaceSmall;

    input,
    textarea{
      width:100%;
      min-height:30px;
      padding:5px 0;

      border:0;
      outline:0;
      background:transparent;
      border-bottom:1px solid $colorGrayDark;
    }

    label{
      color:$colorGrayDark;
      font-weight:$fontWeightSemiBold;
    }

    textarea{
      min-height:100px;
    }
  }

  .form-group{
    & + .form-group{
      margin-top:$spaceBig;
    }

    .form-group-legend{
      @include fontH2;
      margin:$spaceMedium 0;
    }
  }

  .buttons{
    margin-top:$spaceSmall;
    text-align:right;

    button{
      @extend .s-btn;
    }
  }
}
