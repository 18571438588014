$fontMain: Montserrat, Arial, sans-serif;
$fontAccent: $fontMain;

$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightSemiBold: 500;
$fontWeightBold: 600;

$baseFontSize: 14px;

html{
	font-size:$baseFontSize - 3px;

	@include screen-min(md){
		font-size:$baseFontSize - 2px;
	}

	@include screen-min(lg){
		font-size:$baseFontSize - 1px;
	}

	@include screen-min(xl){
		font-size:$baseFontSize;
	}
}

html,
body{
	font-family:$fontMain;
	color:$colorBlack;
}

body{
	font-size:$baseFontSize;
	font-weight:$fontWeightNormal;
}

@mixin fontH1{
	font-family:$fontAccent;
	font-size:rem(42);
	font-weight:$fontWeightBold;
	color:$colorGreen;
}

@mixin fontH2{
	font-size:rem(24);
	font-weight:$fontWeightBold;
	color:$colorGreen;
}

@mixin fontH2Home{
	font-size:rem(42);
	font-weight:$fontWeightBold;
	color:$colorGrayDark;
}

@mixin fontH3{
	font-size:em(14);
	font-weight:$fontWeightBold;
	color:$colorGrayDark;
}

@mixin fontP{
	color:$colorBlack;
}

h1{
	@include fontH1;
	margin-bottom:$spaceSmall;
	text-transform:uppercase;
}

h2{
	@include fontH2;
}

h3{
	@include fontH3;
}

h4{
	@include fontH3;
}

p{
	@include fontP;
}

b,
strong{
	font-weight:$fontWeightBold;
}

a{
	color:$colorGreenDark;

	&:hover{
		color:$colorGreen;
		text-decoration:underline;
	}
}
