.panini{
	$borderPadding: 1.5vw;

	position:relative;
	border:1px solid $colorGrayLighter;
	padding:$borderPadding;
	cursor:pointer;

	.panini-inner{
		border:2px solid $colorGreen;
		background-color:$colorGreen; //get rid of small white stripes
		border-radius:10px;
		overflow:hidden; //cut edges of content and img
	}

	img.panini-nationality{
		height:6vw;
		min-height:40px;
		max-height:75px;
		width:6vw;
		min-width:40px;
		max-width:75px;
		position:absolute;
		top:$borderPadding / 2;
		left:$borderPadding / 2;
		border-radius:50%;
	}

	img.panini-image{
		max-width:100%;
	}

	.panini-content{
		background-color:$colorGreen;
		color:$colorWhite;
		text-align:center;
		font-size:rem(16);
		padding:5px;

		span{
			display:block;
		}

		span.panini-name{
			font-weight:$fontWeightBold;
		}
	}

	@include screen-max(sm){
		padding:10px;

		.panini-inner{
			border-radius:20px;
		}

		img.panini-nationality{
			height:50px;
			width:50px;
			top:5px;
			left:5px;
		}
	}
}
