.reference{
	display:flex;

	.reference-image-wrapper{
		margin-right:$spaceMedium;

		img{
			width:16vw;
			max-width:100px;
		}
	}

	.reference-content{
		p.reference-text{
			margin-bottom:10px;
			font-size:rem(18);
		}

		span.reference-author{
			font-size:rem(14);
			color:$colorGreen;
			font-weight:bold;
		}
	}
}
