body.layout-about{
	input[name="panini"]{
		display:none;
	}

	.paninis{
		margin-top:$spaceBig;
		margin-bottom:$spaceBig;

		@include screen-max(sm){
			display:none;
		}

		label{
			display:block;
		}

		.panini{
			max-width:300px;
			margin-left:auto;
			margin-right:auto;
			background-color:$colorGreen;

			&:hover{
				background-color:$colorGreen!important;
			}
		}
	}

	.panels{
		.panini-mobile{
			max-width:250px;
			margin:$spaceBig 0;
		}

		@include screen-min(sm){
			.panel{
				display:none;
			}

			.panini-mobile{
				display:none;
			}
		}
	}
}
