nav{
	input#nav-trigger,
	label[for="nav-trigger"]{
		display:none;
	}
}

@include screen-max($mobileBreakpoint){
	nav{
		display:flex;
		align-items: center;
		justify-content: flex-end;
		height:100%;

		input#nav-trigger:checked~ .nav-inner-wrapper{
			top:0;
		}

		label[for="nav-trigger"]{
			@include resetLabel;
			display:block;
			font-size:24px;
		}

		.nav-inner-wrapper{
			transition:top 0.3s ease;
			z-index:zIndex(navMobile);
			position:fixed;
			top:calc(-100% - 10px);
			left:0;
			right:0;
			height:100%;
            overflow:auto;
			background-color:$colorWhite;
			@include shadow;
		}
	}
}
