@include screen-max($mobileBreakpoint){
	.mobile-container{
		@include make-container();
		@include make-container-max-widths();
	}
}

body{

	//sticky footer
	display:flex;
	min-height:100vh;
	flex-direction:column;
	main{
		flex:1 0 auto;
	}
	//------------

	.main-container{
		margin-top:$spaceBig;
	}

}
