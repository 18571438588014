section.module-offers{
	.offers-intro{
		text-align:center;
		margin-bottom:$spaceBig;

		h2{
			@include fontH2Home;
		}
	}

	.col{
		margin-bottom:$spaceGrid;
	}

	.offers-box{
		background-color:$colorGrayLight;
		height:100%;

		img{
			max-width:100%;
		}

		.offers-box-content{
			padding:$spaceSmall;
		}

		h3{
			font-size:em(16);
			color:$colorGrayDark;
			font-weight:$fontWeightBold;
		}
	}
}
