footer.page-footer{
	z-index:zIndex(footer);
	position:relative;
	background-color:$colorGrayDarker;
	color:$colorWhite;
	padding:$spaceBig 0;
	font-weight:bold;
	margin-top:$spaceBig;

	@include screen-max($mobileBreakpoint){
		padding:$spaceMedium 0;
	}

    .container{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }

    a.social-media{
        color:$colorWhite;
        font-size:24px;

        &:hover{
            color:$colorGreen;
        }
    }
}
